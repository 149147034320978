import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Unstable_Grid2";
import { useFormik } from "formik";
import { useState } from "react";
import { IoIosArrowRoundBack } from "react-icons/io";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Form, Header } from "semantic-ui-react";
import * as Yup from "yup";
import PrincipalLayout from "../../../components/Common/PrincipalLayout";
import EditClassField from "../../../components/EditClassField";
import { CognitoAuthService } from "../../../Services/CognitoAuthService";
import { registerFirstResponder } from "../../../Services/firstResponderService";
import styles from "./createAgency.module.css";
import ModalTermsAndConditions from "./termAndConditions";
import { FR_ROLE, FR_STATUS } from "../../../utils/constants";

const CreateAgency = () => {
  const navigate = useNavigate();
  const cognitoAuthService = new CognitoAuthService();
  const [activeSessionLength, setActiveSessionLength] = useState(60);
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const handleAgree = (
    isChecked: boolean | ((prevState: boolean) => boolean)
  ) => {
    setIsChecked(isChecked);
  };

  const backOption = () => {
    navigate("/admin");
  };
  const [values, setValues] = useState({
    password: "",
    confPassword: "",
    showPassword: false,
    showConfPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleClickShowConfPassword = () => {
    setValues({ ...values, showConfPassword: !values.showConfPassword });
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const handlePasswordChange = (prop: any) => (event: any) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      city: "",
      state: "",
      zipCode: "",
      phone: "",
      office: "",
      email: "",
      username: "",
      password: "",
      rol: FR_ROLE.FIRST_RESPONDER_OWNER,
      status: FR_STATUS.PENDING,
      confirmpassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(),
      lastname: Yup.string().required(),
      city: Yup.string().required(),
      zipCode: Yup.string().required(),
      state: Yup.string().required(),
      phone: Yup.string()
        .required()
        .matches(phoneRegExp, "Phone number is not valid")
        .length(10, "Please enter a valid mobile number."),
      office: Yup.string().required(),
      email: Yup.string().email().required(),
      username: Yup.string().required(),
      password: Yup.string()
        .required("Password is a required field")
        .min(8, "Must be 8 characters or more")
        .matches(/[A-Z]+/, "One uppercase character")
        .matches(/[a-z]+/, "One lowercase character")
        .matches(/\d+/, "One number")
        .matches(/[!@#$%^&*]+/, "One special case character"),
      confirmpassword: Yup.string()
        .required("Confirm Password is a required field")
        .oneOf([Yup.ref("password")], "Please make sure your passwords match."),
    }),
    onSubmit: async (formData) => {
      try {
        setIsLoading(true);
        const {
          firstname: firstName,
          lastname: lastName,
          city,
          state,
          phone: phoneNumber,
          office,
          email,
          username,
          zipCode,
          rol,
          password,
          status,
        } = formData;

        const fp = await registerFirstResponder({
          firstName,
          lastName,
          city,
          state,
          phoneNumber,
          office,
          email,
          username,
          rol,
          zipCode,
          password,
          agency:
            Math.random().toString(36).substring(2, 15) +
            Math.random().toString(36).substring(2, 15),
          status,
        });
        if (fp) {
          navigate(`/admin/pendingAgencyConfirmation`);
        }
      } catch (err: any) {
        if (err.response && err.response.data && err.response.data.message) {
          alert(err.response.data.message);
        } else {
          alert(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleZipChange = (value: string) => {
    if (value.length > 0) {
      return new RegExp(/\d+/g).test(value) ? value : formik.values.zipCode;
    } else {
      return "";
    }
  };

  return (
    <PrincipalLayout>
      <Box sx={{ flexGrow: 1, color: "#000" }}>
        <Form
          autoComplete="off"
          className={styles.inputForm}
          onSubmit={formik.handleSubmit}
        >
          <Grid container spacing={6} rowSpacing={2}>
            <div className={styles.backArrow} onClick={() => backOption()}>
              <IoIosArrowRoundBack size={30} style={{ color: "#000094" }} />
            </div>
            <Grid maxHeight={56} xs={12}>
              <Header as="h1" style={{ fontSize: "35px" }}>
                Register as an Agency
              </Header>
            </Grid>
            <Grid xs={12}>
              <p style={{ fontSize: "16px" }}>
                {" "}
                Please register as an admin to manage all the first responder
                users in MyMedHistory{" "}
              </p>
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>First Name</label>
              <Form.Input
                value={formik.values.firstname}
                size="big"
                placeholder="Required"
                type="text"
                name="firstname"
                onChange={formik.handleChange}
                required
              />
              {formik.errors.firstname && (
                <span className={styles.errorFormik}>
                  {formik.errors.firstname.replace("firstname", "First Name")}
                </span>
              )}
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>Last Name</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="lastname"
                onChange={formik.handleChange}
                required
              />
              {formik.errors.lastname && (
                <span className={styles.errorFormik}>
                  {formik.errors.lastname.replace("lastname", "Last Name")}
                </span>
              )}
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>City</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="city"
                onChange={formik.handleChange}
                required
              />
              {formik.errors.city && (
                <span className={styles.errorFormik}>
                  {formik.errors.city.replace("city", "City")}
                </span>
              )}
            </Grid>

            <Grid xs={6}>
              <label className={styles.labelFields}>State</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="state"
                onChange={formik.handleChange}
                required
              />
              {formik.errors.state && (
                <span className={styles.errorFormik}>
                  {formik.errors.state.replace("state", "State")}
                </span>
              )}
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>Zip</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="zipCode"
                value={formik.values.zipCode}
                onChange={(e) => {
                  const zip = handleZipChange(e.target.value);

                  formik.setFieldValue("zipCode", zip);
                }}
                required
              />
              {formik.errors.zipCode && (
                <span className={styles.errorFormik}>
                  {formik.errors.zipCode.replace("zipCode", "Zip")}
                </span>
              )}
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>Phone Number</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="phone"
                value={formik.values.phone}
                maxLength={10}
                onChange={formik.handleChange}
                required
              />
              {formik.errors.phone && (
                <span className={styles.errorFormik}>
                  {formik.errors.phone.replace("phone", "Phone Number")}
                </span>
              )}
            </Grid>

            <Grid xs={6}>
              <label className={styles.labelFields}>Agency/Department</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="office"
                onChange={formik.handleChange}
                required
              />
              {formik.errors.office && (
                <span className={styles.errorFormik}>
                  {formik.errors.office.replace("office", "Agency")}
                </span>
              )}
            </Grid>
            <Grid xs={6}>
              <EditClassField
                value={activeSessionLength}
                setValue={setActiveSessionLength}
                label="Agency Default Class"
              />
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>Email</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="email"
                onChange={formik.handleChange}
                required
              />
              {formik.errors.email && (
                <span className={styles.errorFormik}>
                  {formik.errors.email.replace("email", "Email")}
                </span>
              )}
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>Username</label>
              <Form.Input
                size="big"
                placeholder="Required"
                type="text"
                name="username"
                onChange={formik.handleChange}
                required
              />
              {formik.errors.username && (
                <span className={styles.errorFormik}>
                  {formik.errors.username.replace("username", "Username")}
                </span>
              )}
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>Password</label>
              <Input
                name="password"
                type={values.showPassword ? "text" : "password"}
                className={styles.passwordField}
                inputProps={{
                  style: {
                    fontSize: "1.28571429em",
                    borderRight: "none",
                    borderTop: "none",
                  },
                }}
                placeholder="Required"
                onChange={handlePasswordChange("password")}
                onChangeCapture={formik.handleChange}
                value={values.password}
                autoComplete="off"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? (
                        <MdVisibility style={{ color: "#000094" }} />
                      ) : (
                        <MdVisibilityOff style={{ color: "#000094" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                required
              />
              {formik.errors.password && (
                <span style={{ top: "2px" }} className={styles.errorFormik}>
                  {formik.errors.password}
                </span>
              )}
              <br />
              <label
                style={{
                  fontWeight: "400",
                  fontSize: "12px",
                  lineHeight: "16px",
                  color: "#384057",
                }}
              >
                Password must contain at least 8 characters, at least one
                capital letter, one number, and one special symbol
              </label>
            </Grid>
            <Grid xs={6}>
              <label className={styles.labelFields}>Confirm Password</label>
              <Input
                name="confirmpassword"
                className={styles.passwordField}
                type={values.showConfPassword ? "text" : "password"}
                onChange={handlePasswordChange("confPassword")}
                onChangeCapture={formik.handleChange}
                placeholder="Required"
                inputProps={{
                  style: {
                    fontSize: "1.28571429em",
                    borderRight: "none",
                    borderTop: "none",
                  },
                }}
                value={values.confPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowConfPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showConfPassword ? (
                        <MdVisibility style={{ color: "#000094" }} />
                      ) : (
                        <MdVisibilityOff style={{ color: "#000094" }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                required
              />
              {formik.errors.confirmpassword && (
                <span style={{ top: "2px" }} className={styles.errorFormik}>
                  {formik.errors.confirmpassword}
                </span>
              )}
            </Grid>
            <Grid xs={12} sx={{ textAlign: "center" }}>
              <label className={styles.labelFieldsTerms}>
                By checking the box, you accept ...
                <br />
                <ModalTermsAndConditions handleAgree={handleAgree} />
              </label>
            </Grid>
            <Grid xs={12}>
              <Form.Button
                className={styles.buttonStyleDiv}
                type="submit"
                style={{
                  width: "100%",
                  background: "none",
                  color: "white",
                  fontSize: "19px",
                  padding: "23px",
                }}
                disabled={!isChecked}
                loading={isLoading}
              >
                Register
              </Form.Button>
            </Grid>
            <Grid xs={12}>
              <div style={{ width: "100%", margin: "auto" }}>
                {/* <Typography component={'span'} fontWeight="600" fontSize="15px" lineHeight="20px" margin="9px 20px" textAlign="center" color="#999999">
                   <div style={{margin: '10px 0px',width: '48%', borderTop: '1px solid #999', top : '10px' , float : 'left' }}>&nbsp;</div>
                      Or
                   <div style={{margin: '10px 0px',width: '48%', borderTop: '1px solid #999', top : '10px' , float : 'right' }}>&nbsp;</div>
              </Typography> */}
                <Divider style={{ color: "#999999", width: "100%" }}>
                  Or
                </Divider>
              </div>
            </Grid>
            <Grid xs={12} sx={{ textAlign: "center" }}>
              <label className={styles.labelAlreadyAccount}>
                Already have an account?
              </label>
            </Grid>
            <Grid xs={12}>
              <Form.Button
                className={styles.buttonStyleDivWhite}
                type="button"
                onClick={() => backOption()}
                style={{
                  background: "none",
                  color: "#000094",
                  fontSize: "19px",
                  padding: "23px",
                  width: "100%",
                }}
              >
                Login
              </Form.Button>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </PrincipalLayout>
  );
};

export default CreateAgency;
