import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd"
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined"
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import LogoutIcon from "@mui/icons-material/Logout"
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined"
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined"
import WestIcon from '@mui/icons-material/West';
import Dialog from "@mui/material/Dialog"
import Grid from "@mui/material/Grid"
import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import { FC, ReactNode, useState } from "react"
import { useNavigate } from "react-router-dom"
import logo from "../../Assets/logo2.png"
import useAuthUserData from "../../Hooks/useAuthUserData"
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2"
import { FR_ROLE } from "../../utils/constants"

type PAGE_IDS = "scanHistory" | "manageUsers" | "importUsers" | "adminUsers" | "settings"

const PAGES: {
  title: string
  Icon: any
  id: PAGE_IDS
  url: string
}[] = [
  {
    title: "Scan History",
    Icon: PeopleAltOutlinedIcon,
    id: "scanHistory",
    url: "/admin/scanHistory",
  },
  {
    title: "Manage Users",
    Icon: PeopleAltOutlinedIcon,
    id: "manageUsers",
    url: "/admin/manageUsers",
  },
  {
    title: "Import Users",
    Icon: GroupAddOutlinedIcon,
    id: "importUsers",
    url: "/admin/importUsers",
  },
  {
    title: "Admin Users",
    Icon: AccountBoxOutlinedIcon,
    id: "adminUsers",
    url: "/admin/adminUsers",
  },
  {
    title: "Settings",
    Icon: SettingsOutlinedIcon,
    id: "settings",
    url: "/admin/settings",
  },
]

interface Props {
  page: PAGE_IDS
  children: ReactNode
}

const DashboardLayout: FC<Props> = ({ page, children }) => {
  const params = new URLSearchParams(location.search)
  const navigate = useNavigate()
  const { user, loading, cognitoUser } = useAuthUserData()
  const { signOut } = useCognitoAwsV2()
  const [openAdminInfoDialog, setOpenAdminInfoDialog] = useState(false)

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        height="100vh"
        bgcolor="#F2F4F8"
        color="black"
        flexWrap="nowrap"
        overflow="hidden"
      >
        {/* SIDEBAR */}
        <Grid
          item
          xs="auto"
          container
          direction="column"
          justifyContent="space-between"
          alignItems="stretch"
          bgcolor="#09101D"
          color="white"
        >
          <Grid item container direction="column">
            <Grid item container direction="row" columnSpacing={2} p={2} pr={9}>
            <Grid item>
                <img src={logo} alt="logo" style={{ width: 48, objectFit: 'cover'}} />
              </Grid>
              <Grid sx={{ my: 'auto'}} item>
                <Typography fontSize={14} fontWeight={600}>
                  MyMedHistory
                </Typography>
                <Typography fontSize={10} fontWeight={600} color="#9098A1">
                  First Responder Admin
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              container
              direction="row"
              columnSpacing={2}
              px={2}
              py={1}
              bgcolor="#ffffff1a"
            >
              <Grid item>
                <AccountCircleIcon sx={{ fontSize: 32 }} />
              </Grid>
              <Grid item>
                <Typography fontSize={14} fontWeight={600}>
                  {cognitoUser?.attributes.given_name || "Zac"}
                </Typography>
                <Typography fontSize={10} fontWeight={600} color="#9098A1">
                  {cognitoUser?.attributes.email || "Zac@mymedhistory.com"}
                </Typography>
              </Grid>
            </Grid>
            <Grid item p={2} pl={4} container direction="column">
              {PAGES.map((p) => {
                return p.id === "scanHistory" || (p.id === "adminUsers" && user?.rol !== FR_ROLE.FIRST_RESPONDER_OWNER) ? (
                  <></>
                ) : (
                  <Grid
                    item
                    container
                    direction="row"
                    columnSpacing={2}
                    py={1}
                    key={p.id}
                    sx={{
                      cursor: "pointer",
                    }}
                    my={1}
                    borderRadius={1}
                    bgcolor={page === p.id ? "#000094" : "transparent"}
                    onClick={() => (window.location.href = p.url)}
                  >
                    <Grid item>
                      <p.Icon sx={{ fontSize: 20 }} />
                    </Grid>
                    <Grid item>
                      <Typography fontSize={14}>{p.title}</Typography>
                    </Grid>
                  </Grid>
                )
              })}
            </Grid>
          </Grid>
          <Grid
            item
            container
            direction="row"
            columnSpacing={2}
            p={2}
            pl={4}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              signOut()
              window.location.href = "/admin"
            }}
          >
            <Grid item>
              <LogoutIcon sx={{ fontSize: 20 }} />
            </Grid>
            <Grid item>
              <Typography fontSize={14} fontWeight={600}>
                Logout
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {/* CONTENT */}
        <Grid
          item
          container
          direction="column"
          alignItems="stretch"
          p={3}
          overflow="auto"
          flexWrap="nowrap"
        >
          <Grid item mb={3}>
            <Typography fontSize={25} fontWeight={600}>
              {page === "scanHistory" && (
                <IconButton
                  onClick={() => navigate('/admin/manageUsers')}
                  sx={{
                    color: "#000094",
                  }}
                >
                  <WestIcon />
                </IconButton>
              )}
              {`${PAGES.find((p) => p.id === page)?.title} `}
              {page === "scanHistory" && (<span style={{ fontWeight: '400' }}>{`(${String(params.get("name"))?.replaceAll('-', ' ')})`}</span>)}
              {page === "adminUsers" && (
                <IconButton
                  onClick={() => setOpenAdminInfoDialog(true)}
                  sx={{
                    color: "#000094",
                  }}
                >
                  <InfoOutlinedIcon />
                </IconButton>
              )}
            </Typography>
          </Grid>
          <Grid item>{children}</Grid>
        </Grid>
      </Grid>
      <Dialog
        open={openAdminInfoDialog}
        onClose={() => setOpenAdminInfoDialog(false)}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            bgcolor: "white",
            color: "black",
          },
        }}
      >
        <Grid container direction="column" alignItems="center">
          <Grid item container p={2} direction="row-reverse">
            <IconButton
              onClick={() => setOpenAdminInfoDialog(false)}
              sx={{ color: "#000094" }}
            >
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            px={8}
            pb={8}
            mt={1}
            columnSpacing={2}
          >
            <Grid
              item
              sx={{
                color: "#000094",
                fontSize: 50,
              }}
            >
              <AssignmentIndIcon
                sx={{
                  fontSize: 80,
                }}
              />
            </Grid>
            <Grid item xs>
              <Typography fontSize={25} fontWeight={700} color="#242627">
                Who are Admin Users?
              </Typography>
              <Typography
                fontSize={16}
                fontWeight={400}
                gutterBottom
                color="#384057"
              >
                If you need to add to the system other people who can manage
                agency&rsquo;s users you can add them here. You&rsquo;ll be able
                to deactivate or remove their accounts at any time.
              </Typography>
              <Typography fontSize={16} fontWeight={400} color="#384057">
                We will send them invitation email so they can create their own
                accounts related to your agency. The invitation link will be
                valid within 24 hours.{" "}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  )
}

export default DashboardLayout
