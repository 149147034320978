import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import { TbCircleCheck } from "react-icons/tb";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Header } from "semantic-ui-react";
import PrincipalLayout from "../../../components/Common/PrincipalLayout";
import { CustomButton } from "../../../components/CustomButton/CustomButton";
import { CognitoAuthService } from "../../../Services/CognitoAuthService";
import styles from "./createAgency.module.css";

const PendingConfirmationAgency = () => {
  const [code, setCode] = useState("");
  const [error, setError] = useState<null | string>(null);
  const [search, setSearch] = useSearchParams();

  const navigate = useNavigate();
  const loginPage = async () => {
    navigate("/admin");
  };

  return (
    <PrincipalLayout>
      <Box sx={{ flexGrow: 1, color: "#000", padding: "5vw" }}>
        <Grid container spacing={2} rowSpacing={4}>
          <Grid xs={12}>
            <div className={styles.sucessIcon}>
              <TbCircleCheck size={80} style={{ color: "#86E0A7" }} />
            </div>
          </Grid>
          <Grid xs={12}>
            <Header as="h1" style={{ fontSize: "25px", textAlign: "center" }}>
              Your agency creation request has been received
            </Header>
          </Grid>
          <Grid xs={12}>
            <p
              style={{
                fontSize: "16px",
                color: "#384057",
                textAlign: "center",
              }}
            >
              An MMH admin will review your request and notify you once your
              agency is approved. Once you receive the approval email, you will
              be able to log in.
            </p>
          </Grid>

          <Grid xs={12} sx={{ textAlign: "-webkit-center" }}>
            <CustomButton
              style={{
                margin: "14px",
                width: "40%",
                fontSize: "19px",
                background: "none",
                border: "1px solid #000094",
                color: "#000094",
              }}
              text="Return"
              onClick={() => loginPage()}
              sx={{ mt: 5, mb: 1 }}
            />
          </Grid>
        </Grid>
      </Box>
    </PrincipalLayout>
  );
};

export default PendingConfirmationAgency;
