import { VisibilityOffOutlined, VisibilityOutlined } from "@mui/icons-material"
import {
  Alert,
  Box,
  Button,
  Grid,
  InputAdornment,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { FC, ReactElement, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import packageJson from "../../../package.json"
import { CustomButton } from "../../components/CustomButton/CustomButton"
import { RequiredStringField } from "../../Helpers/FormValidations"
import { useCognitoAwsV2 } from "../../Hooks/useCognitoAwsv2"
import { isFRExpired } from "../../Hooks/useFirstResponderService"
import useForm, { FormField } from "../../Hooks/useForm"
import { LayoutSign } from "../../Layout/layout-sign/layout-sign"
import { LayoutContainer } from "../../Layout/layout.container"
import {
  getUserByCodeID,
} from "../../Services/firstResponderService"
import { Checkbox, Form } from "semantic-ui-react"
import { REMEMBER_COOKIE_KEY_USER, getCookie, setCookie } from "../../utils/app"

const CssTextField = styled(TextField)({
  "& label": {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#858C94",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "white",
    borderRadius: "8px",
  },
  "& .MuiOutlinedInput-root": {
    background: "#FFF",
    borderRadius: "8px",
    width: "328px",
    color: "black",
  },
})

export const SignIn: FC<any> = (): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()
  const next = location.state as string

  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const { signIn } = useCognitoAwsV2()
  const [open, setOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const { form, setForm, errors, handleChange, handleBlur, handleSubmit } = useForm(
    {
      email: "",
      password: "",
      codeId: "",
      rememberMe: false
    },
    validateformField,
    submitForm
  )

  // Looks for stored user on cookies 
  useEffect(() => {
    (() => {
      const storedUser = getCookie(REMEMBER_COOKIE_KEY_USER)
      if (storedUser) {
        setEmail(storedUser)
        setForm((prev: any) => ({
          ...prev,
          email: storedUser,
          codeId: storedUser,
          rememberMe: true
        }))
      }
    })()
  }, [])


  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return
    }

    setOpen(false)
  }

  async function submitForm() {
    if (!readyToSubmit()) return
    try {
      setIsLoading(true)
      const codeId = Number(email.replace("FR", ""))
      const user = await getUserByCodeID(codeId)


      if (user.status === "expired" || isFRExpired(user)) {
        setErrorMessage(
          "This temporary password has expired. Please contact your supervisor to generate a new one."
        )
        setOpen(true)
        setIsLoading(false)
        return
      }

      if (user.status === "blocked") {
        setErrorMessage(
          "This account has been blocked. If you believe this is in error, please contact your Agency Administrator."
        )
        setOpen(true)
        setIsLoading(false)
        return
      }

      if (user.status === "new" || user.status === "invited" || (user.status === "unverified" && !user.subCognitoId)) {
        if (form.password === user.otp) {
          navigate("ChangePassword", { state: user })
          //En la vista de create password que cree el usuario en cognito y luego lo actualice cambiandole el estado a "active" y poniendo el otp en null
          setIsLoading(false)
        } else {
          setErrorMessage("The password is incorrect")
          setOpen(true)
          setIsLoading(false)
        }
      } else {
        if (user) {
          await signIn(String(user.email), form.password)
            .then(async (response) => {
              navigate(next ?? "SearchSubscriber", { state: user.email })
              setIsLoading(false)
              if (form.rememberMe)
                setCookie(REMEMBER_COOKIE_KEY_USER, email)
            })
            .catch(async (error) => {
              setIsLoading(false)
              if (error.message.includes("Incorrect")) {
                setErrorMessage("The password is incorrect")
                setOpen(true)
                setIsLoading(false)
              }
            })
        } else {
          setErrorMessage("The Code ID does not exist or invalid")
          setOpen(true)
          setIsLoading(false)
        }

        setIsLoading(false)
      }
    } catch (error) {
      setErrorMessage("The Code ID does not exist or invalid")
      setOpen(true)
      setIsLoading(false)
    }
  }

  const goTo = (route: string) => {
    navigate(route)
  }

  function validateformField(fieldName: string, fieldValue: string): FormField {
    let value: string = ""

    switch (fieldName) {
      case "codeId": {
        value = RequiredStringField(fieldName, fieldValue)
        break
      }
      case "password": {
        value = RequiredStringField(fieldName, fieldValue)
        break
      }
    }
    return {
      name: fieldName,
      value: value,
    } as FormField
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

  function readyToSubmit(): boolean {

    return !(
      !form.codeId ||
      !form.password ||
      !!errors.codeId ||
      !!errors.password ||
      isLoading
    )
  }

  return (
    <>
      <LayoutSign
        children={
          <LayoutContainer>
            <Typography component="h2" variant="h5">
              Sign In
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ mt: 3, mb: 0 }}
            >
              <CssTextField
                name="codeId"
                placeholder="Personal ID Code"
                autoComplete="new-password"
                onChange={(e) => {
                  setEmail(e.target.value)
                  handleChange(e, false)
                }}
                onBlur={handleBlur}
                value={form.codeId}
              />

              <Typography
                fontStyle="normal"
                fontWeight="600"
                fontSize="13px"
                lineHeight="24px"
                margin="9px 40px"
                textAlign="initial"
                color="#FFF"
              >
                Enter your Personal ID Code
              </Typography>

              <CssTextField
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                name="password"
                autoComplete="new-password"
                value={form.password}
                onChange={(e) => {
                  setPassword(e.target.value)
                  handleChange(e, false)
                }}
                onBlur={handleBlur}
                error={!!errors.password}
                helperText={errors.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={handleClickShowPassword}
                      style={{ cursor: "pointer" }}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined style={{ color: "#858C94" }} />
                      ) : (
                        <VisibilityOutlined style={{ color: "#858C94" }} />
                      )}
                    </InputAdornment>
                  ),
                }}
              />

              <Typography
                fontStyle="normal"
                fontWeight="600"
                fontSize="13px"
                lineHeight="24px"
                margin="9px 40px"
                textAlign="initial"
                color="#FFF"
              >
                Enter your password
              </Typography>

              <Grid container
                display="flex"
                style={{ margin: "14px", width: "93%" }}
                direction="row-reverse"
                justifyContent="start"
                gap={3}
              >
                <label htmlFor="rememberMe" style={{
                  fontStyle: "normal",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "white",
                  width: "fit-content",
                  cursor: "pointer",
                  userSelect: "none"
                }}>Remember me?</label>
                <Form.Field
                  control={Checkbox}
                  onChange={(_e: any, { checked }: any) => {
                    handleChange({ target: { name: "rememberMe", value: checked } }, false)
                  }}
                  style={{ display: "inline" }}
                  checked={form.rememberMe}
                  id="rememberMe"
                  name="rememberMe"
                />
              </Grid>

              <Grid
                container
                justifyContent="space-between"
                style={{ margin: "14px", width: "93%" }}
              >
                <Grid item columns={6}>
                  <Button
                    onClick={() => goTo("/CodeRecovery")}
                    variant="text"
                    style={{
                      textTransform: "none",
                      height: 8,
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "white",
                    }}
                  >
                    Forgot ID Code
                  </Button>
                </Grid>
                <Grid item columns={6}>
                  <Button
                    onClick={() => goTo("/PasswordRecovery")}
                    variant="text"
                    style={{
                      textTransform: "none",
                      height: 8,
                      fontStyle: "normal",
                      fontWeight: "600",
                      fontSize: "16px",
                      lineHeight: "24px",
                      color: "white",
                    }}
                  >
                    Forgot Password
                  </Button>
                </Grid>
              </Grid>

              <CustomButton
                style={{ margin: "14px", width: "93%", color: "white" }}
                text="Sign in"
                disabled={!readyToSubmit()}
                isLoading={isLoading}
                type="submit"
                sx={{ mt: 5, mb: 1 }}
              />

              {/* <div style={{ width: "100%", margin: "auto" }}>
                <Typography
                  component="span"
                  fontStyle="normal"
                  fontWeight="600"
                  fontSize="13px"
                  lineHeight="24px"
                  margin="9px 20px"
                  textAlign="center"
                  color="#A2BFEC"
                >
                  <div
                    style={{
                      margin: "10px 0px",
                      width: "40px",
                      borderTop: "1px solid #999",
                      top: "10px",
                      float: "left",
                    }}
                  >
                    &nbsp;
                  </div>
                  Are you an agency administrator?
                  <div
                    style={{
                      margin: "10px 0px",
                      width: "40px",
                      borderTop: "1px solid #999",
                      top: "10px",
                      float: "right",
                    }}
                  >
                    &nbsp;
                  </div>
                </Typography>
              </div>

              <CustomButton
                style={{
                  margin: "14px",
                  width: "93%",
                  background: "none",
                  border: "1px solid #E5E5E5",
                  color: "white",
                }}
                text="Login as Admin"
                isLoading={isLoading}
                onClick={() => goTo("/admin")}
                sx={{ mt: 5, mb: 1 }}
              /> */}
              <Typography
                fontStyle="normal"
                fontWeight="600"
                fontSize="12px"
                lineHeight="24px"
                margin="9px 20px"
                textAlign="center"
                color="#A2BFEC"
              >
                {`v. ${packageJson.version} ${process.env.REACT_APP_STAGE !== "prod "
                  ? process.env.REACT_APP_STAGE
                  : ""
                  }`}
              </Typography>
            </Box>
            <Snackbar
              open={open}
              autoHideDuration={6000}
              onClose={handleClose}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
              <Alert
                onClose={handleClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
          </LayoutContainer>
        }
      ></LayoutSign>
    </>
  )
}

export default SignIn
